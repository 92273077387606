import React from 'react'
import profileJPG from '../../assets/img/profile.jpeg'
import './styles.scss'

//Retorna elemento da seção Sobre
export default function About() {
  return (
    <div className="about-container d-flex flex-row flex-wrap justify-content-center align-items-center text-center w-100 mt-5 p-3">
      <h3 className="w-100 mt-3">Sobre mim</h3>
      <div className="about-item profile-item d-none d-lg-block w-100 px-3">
        <img id="profile" src={profileJPG} alt="Code guy image" />
      </div>
      <div className="about-item about-description w-100 fs-5">
        <h2>Olá! Sou Felipe Moreira.</h2>
        <p className="my-3 px-lg-5">
          Analista de Sistemas com experiência em manutenção de infraestrutura de TI, Telecom e CFTV em ambientes Linux e Microsoft. Desde o inicio de 2020 tenho me dedicado ao estudo de aplicações Full-stack.
        </p>
        <p>Fique a vontade para interagir!</p>
      </div>
    </div>
  )
}