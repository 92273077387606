import React, { Component } from 'react'
import './components/styles/App.scss';

import Header from './components/Header/index.jsx';
import Banner from './components/Banner/index.jsx';
import Formulario from './components/Formulario/Formulario';
import GridCaroussel from './components/GridCaroussel/index.jsx';
import Habilidades from './components/Habilidades/Habilidades';
import About from './components/About/index.jsx';
import Footer from './components/Footer/index.jsx';

export default class App extends Component {

  //Array com dados dos projetos
  arrayData = [
    {
      name: 'Memory Fighter',
      host: 'https://moreirafelipe.github.io/memory-fighter/',
      repo: 'https://github.com/moreirafelipe/memory-fighter',
      description: 'Jogo da memória que simula o menu do jogo Street Fighter com Vanilla Javascript e SASS.'
    },
    {
      name: 'Travels Book',
      host: 'https://moreirafelipe.github.io/dh-front2-checkpoint1/',
      repo: 'https://github.com/moreirafelipe/dh-front2-checkpoint1',
      description: 'Projeto de uma aplicação para organização de viajens utilizando Vanilla Javacript, CSS 3 e LocalStoraging.'
    },
    {
      name: 'My Flix',
      host: 'https://dh-front3-myflix-46cjrfj0q-tstream.vercel.app/',
      repo: 'https://github.com/moreirafelipe/dh-front3-myflix',
      description: 'Projeto de uma interface responsiva para uma plataforma de streaming utilizando React e CSS.'
    },
    {
      name: 'Calculadora',
      host: 'https://moreirafelipe.github.io/calculadora/',
      repo: 'https://github.com/moreirafelipe/calculadora',
      description: 'Projeto de uma calculadora simples utilizando Vanilla Javascript e CSS grid layout para a disciplina Front-end 2.'
    },
    {
      name: 'Lista de felinos',
      host: 'https://moreirafelipe.github.io/dh-exercicioListaFelinos/',
      repo: 'https://github.com/moreirafelipe/dh-exercicioListaFelinos',
      description: 'Projeto de uma aplicação desenvolvida em Vanilla Javascript que renderiza cards de animais no DOM.'
    }
  ]

  //Renderização das seções da página
  render() {
    return (
      <div id="app" >
        <Header />
        <main>
          <Banner projects={this.arrayData} />
        </main>
        <section id="carousel" className="mt-5 pt-5">
          <GridCaroussel projects={this.arrayData} />
        </section>
        <section id="skills" className="d-flex w-100 mt-5 bg-dark text-light">
          <Habilidades />
        </section>
        <section id="form" className="d-flex w-100 mt-5 pt-5 bg-light text-dark">
          <Formulario />
        </section>
        <section id="about" className="d-flex w-100 bg-dark text-white mt-5">
          <About />
        </section>
        <footer className="d-flex justify-content-center text-center w-100 bg-dark h-30 text-white px-2">
          <Footer />
        </footer>
      </div>
    );
  }
}