import React from 'react'

export default function Quote() {
  return (
    <figure className="w-100 text-center">
      <blockquote className="blockquote">
        <p>Alcançar objetivos é investir tempo em melhorar a si mesmo!</p>
      </blockquote>
      <figcaption className="blockquote-footer">
        Autor <cite title="Source Title">desconhecido</cite>
      </figcaption>
    </figure>
  )
}