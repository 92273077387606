import React from 'react'
import './styles.scss'

//Recebe itens highlight e retorna os elementos card
export function Highlight({ children }) {
  return (
    <>
      {children}
    </>
  )
}