import React from 'react';
import './styles.scss'

export default function Banner(props) {
  return (
    <>
      <div id="carouselExampleCaptions" className="carousel slide mb-4" data-bs-ride="carousel">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="carousel-caption d-block">
              <h2 className="display-3">{props.projects[0].name}</h2>
              <p className="display-6">{props.projects[0].description}</p>
              <p><a href={props.projects[0].host}> <button className="btn btn-primary mx-2 my-3">Acesse</button></a> <a href={props.projects[0].repo}><button className="btn btn-light">Repositorio</button></a></p>
            </div>
          </div>
          <div className="carousel-item">
            <div className="carousel-caption d-block">
              <h2 className="display-3">{props.projects[1].name}</h2>
              <p className="display-6">{props.projects[1].description}</p>
              <p>
                <a href={props.projects[1].host}>
                  <button className="btn btn-primary mx-2 my-3">Acesse</button>
                </a>
                <a href={props.projects[1].repo}>
                  <button className="btn btn-light">Repositorio</button>
                </a>
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <div className="carousel-caption d-block">
              <h2 className="display-3">{props.projects[2].name}</h2>
              <p className="display-6">{props.projects[2].description}</p>
              <p>
                <a href={props.projects[2].host}>
                  <button className="btn btn-primary mx-2 my-3">Acesse</button>
                </a>
                <a href={props.projects[2].repo}>
                  <button className="btn btn-light">Repositorio</button>
                </a>
              </p>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  )
}