import React from 'react'
import { Carousel } from '@trendyol-js/react-carousel';
import { Highlight } from '../HighLight';
import Quote from '../Quote';

//Importando estilos
import './styles.scss'

//Importando setas de navegação do carousel em SVGs
import { ReactComponent as BackSVG } from '../../assets/img/back.svg';
import { ReactComponent as ForwardSVG } from '../../assets/img/forward.svg';

//Importando imagens PNG
import memoryfighter from '../../assets/img/memoryfighter.PNG';
import travelsbook from '../../assets/img/travelsbook.PNG';
import myFlix from '../../assets/img/myflix.PNG';
import calculadora from '../../assets/img/calculadora.PNG';
import felinos from '../../assets/img/felinos.PNG';

//Function component que exporta o componentcarousel
export default function GridCaroussel(props) {

  const imgName = [memoryfighter, travelsbook, myFlix, calculadora, felinos];

  //Iterando array de dados e retornando componentes que contém os cards como props children
  return (
    <div id="projetos" className="d-flex flex-column flex-wrap m-auto my-5">
      <h3>Projetos</h3>
      <div className="d-none d-md-block">
        <Quote />
      </div>
      <Carousel infinite={true} responsive={true} show={1} slide={1} swiping={true} useArrowKeys={true} leftArrow={<BackSVG />} rightArrow={<ForwardSVG />} className="d-flex carousel-mobile m-auto w-100">

        {props.projects.map((project, index) => {
          return (
            <Highlight key={index}>
              <div className="card card-mobile bg-dark text-light m-4">
                <img src={imgName[index]} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title">{project.name}</h5>
                  <p className="card-text">{project.description}</p>
                  <a href={project.host} className="btn btn-primary">Acesse</a>
                  <a href={project.repo} className="btn btn-light mx-2">Repositório</a>
                </div>
              </div>
            </Highlight>
          )
        })}
      </Carousel>
      <Carousel infinite={true} responsive={true} show={3.5} slide={1} swiping={true} useArrowKeys={true} leftArrow={<BackSVG />} rightArrow={<ForwardSVG />} className="d-flex carousel-desktop m-auto w-100">

        {props.projects.map((project, index) => {
          return (
            <Highlight key={index}>
              <div className="card card-desktop bg-dark text-light m-auto p-0">
                <img src={imgName[index]} className="card-img-top" alt="..." />
                <div className="card-body p-3">
                  <h5 className="card-title">{project.name}</h5>
                  <p className="card-text">{project.description}</p>
                  <a href={project.host} className="btn btn-primary">Acesse</a>
                  <a href={project.repo} className="btn btn-light mx-2">Repositório</a>
                </div>
              </div>
            </Highlight>
          )
        })}
      </Carousel>
    </div>
  )
}